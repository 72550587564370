import React, { useState } from "react";
import { Container, Form, Button, Row, Col, Alert, Stack } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import InputField from "../../components/InputField/InputField";
import BadgeTemaple from "../../components/Badge/BadgeTemaple";
import "./CFI_InputPage.css";

const CFI_InputPage = ({ onSubmit, loading, error }) => {
    const [numQuestions, setNumQuestions] = useState(1);
    const [dropdownValue, setDropdownValue] = useState(1);
    const [inputFields, setInputFields] = useState([{ value: "" }]);
    const navigate = useNavigate();

    const bannedWords = ['幹你', '屌', '操你', '老母', '撚', '柒', '閪', '含家', '鳩', '仆街', '食屎', '死開', '廢噏']

    const handleNumQuestionsChange = (e) => {
        setNumQuestions(parseInt(e.target.value, 10));
    };

    const handleDropdownChange = (e) => {
        setDropdownValue(parseInt(e.target.value, 10));
    };

    const handleInputChange = (index, event) => {
        const values = [...inputFields];
        const inputValue = event.target.value;
        let filteredValue = inputValue.trim().replace(/[A-Za-z0-9\s~`!@#$%^&*()_+-=[\]{}|\\;:'",<.>/?]/g, "");
        bannedWords.forEach(word => {
            const regex = new RegExp(word, 'gi');
            filteredValue = filteredValue.replace(regex, '');
        })

        values[index].value = filteredValue;

        setInputFields(values);
    };

    const handleAddField = () => {
        setInputFields([...inputFields, { value: "" }]);
    };

    const handleRemoveField = (index) => {
        const values = [...inputFields];
        values.splice(index, 1);
        setInputFields(values);
    };

    const isSubmitDisabled = inputFields.every((field) => field.value.trim() === "");

    const handleSubmit = async () => {
        const combinedInputFields = inputFields.map((field) => field.value).join(",");
        const result = await onSubmit({
            numQuestions,
            dropdownValue,
            inputFields: combinedInputFields,
        });

        if (result) {
            navigate('/cfi-results');
        }
    };

    return (
        <Container fluid className="pb-5">
            <Row className="pt-5 pb-3 justify-content-md-center align-items-center gap-5 topic">
                <Col md="auto">
                    <Stack gap={5}>
                        <div className="pt-5">
                            <img
                                src="/title.png"
                                alt="Title"
                                className="d-inline-block align-top img-fluid"
                            />
                        </div>
                        <div className="pb-5 fs-5 fw-medium lh-1 text-white">
                            「中文填充神器」是一個產生中文填充文字的工具。<br />
                            簡單易用，適合需要中文測試文本的場景適合需要<br />
                            中文測試文本的場景<br />
                        </div>
                    </Stack>
                </Col>
                <Col md="auto">
                    <img
                        src="/person.png"
                        alt=""
                        className="img-fluid mx-auto d-block"
                    />

                </Col>
                <Col md="auto">
                    <Stack gap={3} md="auto">
                        <div className="ms-1 fs-5 fw-bold text-black" md="auto">
                            例子
                        </div>
                        <Row md="auto" className="ms-1 gap-3">
                            <BadgeTemaple value="貓" />
                            <BadgeTemaple value="勞力士" />
                            <BadgeTemaple value="宇宙最強" />
                            <BadgeTemaple value="雪糕" />
                        </Row>
                        <Row md="auto" className="ms-1 gap-3 align-items-center fw-medium">
                            小明家養了一隻可愛的<BadgeTemaple value="貓" />，每天都會陪他玩耍。
                        </Row>
                    </Stack>
                </Col>
            </Row>

            <Row className="input-row-content">
                <Col md="auto" className="mt-5">
                    <Form className="ms-2">
                        <Form.Group className="mb-5">
                            <h1>創建你的試卷</h1>
                            <br></br>
                            <br></br>
                            <Form.Label className="fs-5 fw-bold pb-1" style={{ display: 'block' }}>步驟 1</Form.Label>
                            <Form.Label className="fs-6 fw-medium pe-2">題目數量 :</Form.Label>
                            <Form.Control
                                type="number"
                                value={numQuestions}
                                onChange={handleNumQuestionsChange}
                                min="1"
                                max="20"
                                style={{ width: '60px', display: 'inline', border: '1px solid #000' }}
                            />
                        </Form.Group>

                        <Form.Group className="mb-5">
                            <Form.Label className="fs-5 fw-bold pe-2">步驟 2</Form.Label>
                            <Form.Label className="fs-5 fw-medium pe-2">|</Form.Label>
                            <Form.Label className="fs-5 fw-bold" style={{ display: 'inline-block' }}>題目難度</Form.Label>
                            <br></br>
                            <Form.Label className="fs-6 fw-medium pe-2">選擇年級 :</Form.Label>
                            <Form.Select
                                value={dropdownValue}
                                onChange={handleDropdownChange}
                                style={{ width: '60px', display: 'inline', border: '1px solid #000' }}
                            >
                                {[1, 2, 3, 4, 5, 6].map((num) => (
                                    <option key={num} value={num}>
                                        {num}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-5">
                            <Form.Label className="fs-5 fw-bold pe-2">步驟 3</Form.Label>
                            <Form.Label className="fs-5 fw-medium pe-2">|</Form.Label>
                            <Form.Label className="fs-5 fw-bold" style={{ display: 'inline-block' }}>輸入詞語</Form.Label>
                            <br></br>
                            {inputFields.map((field, index) => (
                                <InputField
                                    key={index}
                                    value={field.value}
                                    onChange={handleInputChange}
                                    onRemove={handleRemoveField}
                                    index={index}
                                />
                            ))}
                            <Button
                                className="text-black"
                                onClick={handleAddField}
                                style={{ background: '#D9D9D9', border: 'none' }}
                            >
                                +
                            </Button>
                        </Form.Group>

                        <Button
                            variant="primary"
                            onClick={handleSubmit}
                            disabled={isSubmitDisabled || loading}
                            className="text-white fw-bold fs-5 text-align-center"
                            style={{ width: '180px', height: '42px', backgroundColor: '#55D9BE', border: 'none' }}
                        >
                            {loading ? "創建中..." : "創建試卷"}
                        </Button>

                        {error && (
                            <Alert variant="danger" className="mt-3">
                                {error}
                            </Alert>
                        )}
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default CFI_InputPage;
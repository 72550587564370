import React, { useState } from "react";
import { jsPDF } from "jspdf";
import { Container, Button, ListGroup, Form, Row, Col } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { regenerateQuestions } from "../../services/api";
import { formatChoices } from "../../utils/formatChoices";
import BadgeTemaple from "../../components/Badge/BadgeTemaple"
import "./CFI_ResultPage.css";
import "../../assets/fonts/Noto-normal.js"

const CFI_ResultPage = ({ chatId, choiceList, generatedQuestionList }) => {
    const [checkedQuestions, setCheckedQuestions] = useState(
        Array(generatedQuestionList.length).fill(false)
    );
    const [questions, setQuestions] = useState(generatedQuestionList);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleCheckboxChange = (index) => {
        const newCheckedQuestions = [...checkedQuestions];
        newCheckedQuestions[index] = !newCheckedQuestions[index];
        setCheckedQuestions(newCheckedQuestions);
    };

    const handleRegenerate = async () => {
        const selectedQuestions = questions.filter(
            (_, index) => checkedQuestions[index]
        );
        const newMessage = selectedQuestions.map((q) => q.question).join("\n");
        const count = selectedQuestions.length;

        setLoading(true);

        try {
            const data = await regenerateQuestions({ chatId, count, newMessage });
            const updatedQuestions = questions.map((question, index) =>
                checkedQuestions[index] ? data.generated_question_list.shift() || question : question
            );
            setQuestions(updatedQuestions);
            setCheckedQuestions(Array(updatedQuestions.length).fill(false));
        } catch (error) {
            console.error("Error regenerating questions:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleSaveWithChoices = () => {
        const doc = new jsPDF();
        doc.setFont("Noto");
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(18);
        doc.setFillColor(255, 255, 255);
        doc.rect(0, 0, 210, 297, "F");

        doc.text("選出適當的詞語寫在___上。", 10, 10);
        doc.setFontSize(14);
        doc.text("詞語選項:", 10, 20);
        formatChoices(choiceList).forEach((row, index) => {
            doc.text(row, 10, 30 + index * 10);
        });

        let yOffset = 60;
        questions.forEach((item, index) => {
            if (checkedQuestions[index]) {
                doc.text(`${index + 1}. ${item.question}`, 10, yOffset);
                yOffset += 10;
            }
        });

        doc.addPage();
        doc.setFontSize(18);
        doc.text("答案", 10, 10);
        doc.setFontSize(14);
        yOffset = 20;
        questions.forEach((item, index) => {
            if (checkedQuestions[index]) {
                doc.text(`${index + 1}. ${item.answer}`, 10, yOffset);
                yOffset += 10;
            }
        });

        doc.save("result_with_choices.pdf");
    };

    const handleSaveWithoutChoices = () => {
        const doc = new jsPDF();
        doc.setFont("Noto");
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(18);
        doc.setFillColor(255, 255, 255);
        doc.rect(0, 0, 210, 297, "F");

        doc.text("填寫適當的詞語寫在___上。", 10, 10);
        let yOffset = 20;
        questions.forEach((item, index) => {
            if (checkedQuestions[index]) {
                doc.text(`${index + 1}. ${item.question}`, 10, yOffset);
                yOffset += 10;
            }
        });

        doc.addPage();
        doc.setFontSize(18);
        doc.text("答案", 10, 10);
        doc.setFontSize(14);
        yOffset = 20;
        questions.forEach((item, index) => {
            if (checkedQuestions[index]) {
                doc.text(`${index + 1}. ${item.answer}`, 10, yOffset);
                yOffset += 10;
            }
        });

        doc.save("result_without_choices.pdf");
    };

    const isRegenerateDisabled = !checkedQuestions.some((checked) => checked);

    return (
        <Container fluid className="pb-5">
            <Row className="result-title-bg">
                <div>
                    <img
                        src="/title.png"
                        alt="Title"
                        className="result-title-img img-fluid"
                    />
                </div>
            </Row>
            <Button
                variant=""
                onClick={() => navigate('/cfi-input')}
                disabled={loading}
                className="mb-5 text-black fs-5 result-back-button result-back-button-margin">
                {'< 創建試卷'}
            </Button>
            <h1 className="fw-bold fs-5 mb-1 result-choice-title">詞語選項</h1>
            <ListGroup horizontal className="mb-4 result-choice-group">
                {choiceList.map((choice, index) => (
                    <ListGroup.Item style={{ border: 'none' }}>
                        <BadgeTemaple value={choice} />
                    </ListGroup.Item>
                ))}
            </ListGroup>

            <Form className="result-form">
                <h1 className="fs-5 mt-5 mb-5">生成問題</h1>
                {questions.map((item, index) => (
                    <Form.Check
                        key={index}
                        type="checkbox"
                        label={
                            <>
                                <strong>{index + 1}. </strong>
                                {item.question}
                                <br />
                                <br className="result-br" />
                                <strong className="result-answer">答案:</strong> <strong>{item.answer}</strong>
                            </>
                        }
                        checked={checkedQuestions[index]}
                        onChange={() => handleCheckboxChange(index)}
                        disabled={loading}
                        className="mb-5"
                        style={{ "--bs-form-check-bg": "#D9D9D9", "--bs-border-width": "1px", "--bs-border-color": "#000" }}
                    />
                ))}
            </Form>
            <Row className="result-button-group gap-3">
                <Col md="auto" className="text-center">
                    <Button
                        onClick={handleSaveWithChoices}
                        disabled={loading}
                        className="fw-bold"
                        style={{ width: '142px', height: '40px', backgroundColor: '#55D9BE', border: 'none' }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ marginRight: '.5rem' }}>
                            <path d="M21 14C20.7348 14 20.4804 14.1054 20.2929 14.2929C20.1054 14.4804 20 14.7348 20 15V19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V15C4 14.7348 3.89464 14.4804 3.70711 14.2929C3.51957 14.1054 3.26522 14 3 14C2.73478 14 2.48043 14.1054 2.29289 14.2929C2.10536 14.4804 2 14.7348 2 15V19C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H19C19.7956 22 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 19V15C22 14.7348 21.8946 14.4804 21.7071 14.2929C21.5196 14.1054 21.2652 14 21 14ZM11.29 15.71C11.3851 15.801 11.4972 15.8724 11.62 15.92C11.7397 15.9729 11.8691 16.0002 12 16.0002C12.1309 16.0002 12.2603 15.9729 12.38 15.92C12.5028 15.8724 12.6149 15.801 12.71 15.71L16.71 11.71C16.8983 11.5217 17.0041 11.2663 17.0041 11C17.0041 10.7337 16.8983 10.4783 16.71 10.29C16.5217 10.1017 16.2663 9.99591 16 9.99591C15.7337 9.99591 15.4783 10.1017 15.29 10.29L13 12.59V3C13 2.73478 12.8946 2.48043 12.7071 2.29289C12.5196 2.10536 12.2652 2 12 2C11.7348 2 11.4804 2.10536 11.2929 2.29289C11.1054 2.48043 11 2.73478 11 3V12.59L8.71 10.29C8.61676 10.1968 8.50607 10.1228 8.38425 10.0723C8.26243 10.0219 8.13186 9.99591 8 9.99591C7.86814 9.99591 7.73757 10.0219 7.61575 10.0723C7.49393 10.1228 7.38324 10.1968 7.29 10.29C7.19676 10.3832 7.1228 10.4939 7.07234 10.6158C7.02188 10.7376 6.99591 10.8681 6.99591 11C6.99591 11.1319 7.02188 11.2624 7.07234 11.3842C7.1228 11.5061 7.19676 11.6168 7.29 11.71L11.29 15.71Z" fill="white" />
                        </svg>
                        供詞版本
                    </Button>
                </Col>
                <Col md="auto" className="text-center">
                    <Button
                        variant="success"
                        onClick={handleSaveWithoutChoices}
                        disabled={loading}
                        className="fw-bold"
                        style={{ width: '142px', height: '40px', backgroundColor: '#5583D9', border: 'none' }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ marginRight: '.5rem' }}>
                            <path d="M21 14C20.7348 14 20.4804 14.1054 20.2929 14.2929C20.1054 14.4804 20 14.7348 20 15V19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V15C4 14.7348 3.89464 14.4804 3.70711 14.2929C3.51957 14.1054 3.26522 14 3 14C2.73478 14 2.48043 14.1054 2.29289 14.2929C2.10536 14.4804 2 14.7348 2 15V19C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H19C19.7956 22 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 19V15C22 14.7348 21.8946 14.4804 21.7071 14.2929C21.5196 14.1054 21.2652 14 21 14ZM11.29 15.71C11.3851 15.801 11.4972 15.8724 11.62 15.92C11.7397 15.9729 11.8691 16.0002 12 16.0002C12.1309 16.0002 12.2603 15.9729 12.38 15.92C12.5028 15.8724 12.6149 15.801 12.71 15.71L16.71 11.71C16.8983 11.5217 17.0041 11.2663 17.0041 11C17.0041 10.7337 16.8983 10.4783 16.71 10.29C16.5217 10.1017 16.2663 9.99591 16 9.99591C15.7337 9.99591 15.4783 10.1017 15.29 10.29L13 12.59V3C13 2.73478 12.8946 2.48043 12.7071 2.29289C12.5196 2.10536 12.2652 2 12 2C11.7348 2 11.4804 2.10536 11.2929 2.29289C11.1054 2.48043 11 2.73478 11 3V12.59L8.71 10.29C8.61676 10.1968 8.50607 10.1228 8.38425 10.0723C8.26243 10.0219 8.13186 9.99591 8 9.99591C7.86814 9.99591 7.73757 10.0219 7.61575 10.0723C7.49393 10.1228 7.38324 10.1968 7.29 10.29C7.19676 10.3832 7.1228 10.4939 7.07234 10.6158C7.02188 10.7376 6.99591 10.8681 6.99591 11C6.99591 11.1319 7.02188 11.2624 7.07234 11.3842C7.1228 11.5061 7.19676 11.6168 7.29 11.71L11.29 15.71Z" fill="white" />
                        </svg>
                        不供詞版本
                    </Button>
                </Col>
                <Col md="auto" className="text-center">
                    <Button
                        variant="danger"
                        onClick={handleRegenerate}
                        disabled={isRegenerateDisabled || loading}
                        className="text-black fw-bold"
                        style={{ width: '142px', height: '40px', backgroundColor: '#D9D9D9', border: 'none' }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ marginRight: '.5rem' }}>
                            <path d="M21 11C20.7348 11 20.4804 11.1054 20.2929 11.2929C20.1054 11.4804 20 11.7348 20 12C20.0119 13.873 19.3703 15.6916 18.1857 17.1425C17.001 18.5933 15.3475 19.5857 13.51 19.9486C11.6724 20.3115 9.76578 20.0223 8.11851 19.1308C6.47123 18.2392 5.18641 16.8012 4.48533 15.0643C3.78424 13.3273 3.71079 11.4003 4.27762 9.61508C4.84445 7.82984 6.01607 6.29813 7.5907 5.28378C9.16533 4.26942 11.0444 3.83591 12.9043 4.05791C14.7641 4.27991 16.4884 5.14352 17.78 6.5H15.38C15.1148 6.5 14.8604 6.60536 14.6729 6.79289C14.4854 6.98043 14.38 7.23478 14.38 7.5C14.38 7.76522 14.4854 8.01957 14.6729 8.20711C14.8604 8.39464 15.1148 8.5 15.38 8.5H19.91C20.1752 8.5 20.4296 8.39464 20.6171 8.20711C20.8046 8.01957 20.91 7.76522 20.91 7.5V3C20.91 2.73478 20.8046 2.48043 20.6171 2.29289C20.4296 2.10536 20.1752 2 19.91 2C19.6448 2 19.3904 2.10536 19.2029 2.29289C19.0154 2.48043 18.91 2.73478 18.91 3V4.77C17.2447 3.17806 15.0808 2.20995 12.784 2.02933C10.4873 1.84871 8.19863 2.46664 6.30492 3.77869C4.41121 5.09074 3.02861 7.01641 2.39083 9.23019C1.75305 11.444 1.89921 13.8101 2.80461 15.9285C3.71001 18.047 5.31911 19.7878 7.3599 20.8568C9.40069 21.9258 11.748 22.2574 14.005 21.7955C16.2621 21.3336 18.2904 20.1065 19.7471 18.3217C21.2039 16.5369 21.9997 14.3038 22 12C22 11.7348 21.8946 11.4804 21.7071 11.2929C21.5196 11.1054 21.2652 11 21 11Z" fill="black" />
                        </svg>
                        {loading ? "生成中..." : "重生生成問題"}
                    </Button>
                </Col>

            </Row>
        </Container>
    );
};

export default CFI_ResultPage;
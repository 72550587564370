import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button, Row, Col, Stack } from 'react-bootstrap';
import './HomePage.css'

const HomePage = () => {
    const navigate = useNavigate();

    return (
        <Container fluid className="pb-5">
            <Row className='align-items-center shadow homepage-title-row homepage-title-row-gap'>
                <Col md="auto">
                    <img
                        src="/person1.png"
                        alt=""
                        className='img-fluid mx-auto d-block homepage-person-img'
                    />
                </Col>
                <Col md="auto" className='homepage-title-text-col'>
                    <Stack gap={4} className='homepage-title-text-stack'>
                        <h1 style={{ fontSize: '32px', fontStyle: 'normal', fontWeight: '700' }}>AI 助你準備考試 操卷簡單輕鬆</h1>
                        <p style={{ fontSize: '16px' }}>利用先進 <strong>AI</strong> 技術訓練出全新人工智能考試神器，幾秒鐘便創建大量練習試題，無論係學生、家長、教育工作者，還是任何需要備試嘅人士，都可以根據個人需求，生成度身訂造、高質素嘅題目，令考試準備更高效、更有成效。</p>
                    </Stack>
                </Col>
            </Row>
            <Row
                className='justify-content-center row-cols-3 gap-5'
                style={{ marginTop: '5rem', marginBottom: '3rem' }}
            >
                <Button
                    onClick={() => navigate('/cfi-input')}
                    style={{
                        width: '310px',
                        height: '160px',
                        backgroundColor: '#AFD2F3',
                        borderRadius: '20px',
                        boxShadow: '-4px -4px 4px 0px rgba(0, 0, 0, 0.25) inset',
                        border: 'none'
                    }}
                >
                    <img
                        src="/title.png"
                        alt=""
                        width={215}
                        height={56}
                    />
                </Button>

                <Button
                    onClick={() => navigate('/')}
                    disabled='true'
                    className='fs-2 fst-italic fw-bold '
                    style={{
                        width: '310px',
                        height: '160px',
                        backgroundColor: '#55D9BE',
                        borderRadius: '20px',
                        boxShadow: '-4px -4px 4px 0px rgba(0, 0, 0, 0.25) inset',
                        border: 'none'
                    }}
                >
                    COMING SOON
                </Button>

                <Button
                    onClick={() => navigate('/')}
                    disabled='true'
                    className='fs-2 fst-italic fw-bold'
                    style={{
                        width: '310px',
                        height: '160px',
                        backgroundColor: '#EBF39F',
                        borderRadius: '20px',
                        boxShadow: '-4px -4px 4px 0px rgba(0, 0, 0, 0.25) inset',
                        border: 'none'
                    }}
                >
                    COMING SOON
                </Button>
            </Row>
        </Container>
    );
};

export default HomePage;
import React from "react";
import { useNavigate } from "react-router-dom";
import { Navbar, Container } from "react-bootstrap";
import "./Header.css"

const Header = () => {
    const navigate = useNavigate();

    const handleLogoClick = () => {
        navigate("/"); // Redirect to the homepage
    };

    return (
        <Navbar bg="white" expand="lg" >
            <Container fluid className="py-3 header-container">
                <Navbar.Brand onClick={handleLogoClick} style={{ cursor: "pointer" }} className="header-navbar">
                    <img
                        src="/logo.png"
                        alt="ExamsHelper Logo"
                        className="d-inline-block img-fluid"
                    />
                </Navbar.Brand>
            </Container>
        </Navbar>
    );
};

export default Header;
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

export const fetchQuestions = async (data) => {
    const response = await axios.post(`${API_BASE_URL}/process`, data);
    return response.data;
};

export const regenerateQuestions = async (data) => {
    const response = await axios.post(`${API_BASE_URL}/continue`, data);
    return response.data;
};
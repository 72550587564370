import { Form, Button, Row, Col } from "react-bootstrap";

const InputField = ({ value, onChange, onRemove, index }) => (
    <Row className="mb-2">
        <Col>
            <Form.Control
                type="text"
                value={value}
                onChange={(e) => onChange(index, e)}
                placeholder={`輸入詞語 ${index + 1}`}
                style={{ width: '180px', display: 'inline', border: '1px solid #000', backgroundColor: '#D9D9D9' }}
                className="fw-medium text-black"
                maxLength={6}
            />
        </Col>
        <Col>
            <Button
                className="text-black"
                onClick={() => onRemove(index)}
                style={{ background: '#D9D9D9', border: 'none' }}
            >
                -
            </Button>
        </Col>
    </Row>
);

export default InputField;
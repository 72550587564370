import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header/Header';
import HomePage from './pages/HomePage/HomePage';
import CFI_InputPage from './pages/CFI_InputPage/CFI_InputPage';
import CFI_ResultPage from './pages/CFI_ResultPage/CFI_ResultPage';
import { fetchQuestions } from './services/api';
import { useNavigate } from "react-router-dom";

const App = () => {
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleSubmit = async (data) => {
        setLoading(true);
        setError(null);

        try {
            const resultData = await fetchQuestions(data);
            setResult(resultData);
            return resultData;
        } catch (error) {
            setError(error.message || 'An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Header />
            <Routes>
                {/* <Route path="/" element={
                    <CFI_ResultPage
                        chatId={"123456"}
                        choiceList={["貓", "勞力士", "宇宙最強", "雪糕"]}
                        generatedQuestionList={[
                            {
                                "question": "舞者的每一個動作都充滿了力量與美感，讓觀眾們________，全場響起了熱烈的掌聲。",
                                "answer": "五體投地"
                            },
                            {
                                "question": "每當談到未來的職業選擇，他就顯得________，總是無法確定自己真正想要的是什麼。",
                                "answer": "三心兩意"
                            },
                            {
                                "question": "這個新方案不僅解決了舊有的問題，還帶來了額外的好處，可謂是________。",
                                "answer": "一箭雙雕"
                            }
                        ]}
                    />

                } /> */}
                <Route path="/" element={<HomePage />} />
                <Route
                    path="/cfi-input"
                    element={
                        <CFI_InputPage
                            onSubmit={handleSubmit}
                            loading={loading}
                            error={error}
                        />
                    }
                />
                <Route
                    path="/cfi-results"
                    element={
                        result ? (
                            <CFI_ResultPage
                                chatId={result.chatId}
                                choiceList={result.choice_list}
                                generatedQuestionList={result.generated_question_list}
                            />
                        ) : (
                            <Navigate to="/cfi-input" replace />
                        )
                    }
                />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </>
    );
};

export default App;